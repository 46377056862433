<template>
    <Navigation title="Ubah Kata Sandi"/>
    <div class="card my-3">
        <div class="card-body">
            <label for="inputPassword" class="form-label">Kata Sandi Lama</label>
            <div class="input-group">
                <input v-if="!showPasswordOld" v-model="oldPassword" type="password" class="form-control"
                       :class="{'is-invalid':errors['oldPassword']}" id="inputPasswordOld"
                       placeholder="Insert your password here">
                <input v-else v-model="oldPassword" type="text" class="form-control"
                       :class="{'is-invalid':errors['oldPassword']}" id="inputPasswordOld"
                       placeholder="Insert your password here">
                <button class="btn btn-outline-secondary" id="btn-submit" @click="togglePassword('old')">
                    <i class="bi" :class="{'bi-eye':showPasswordOld, 'bi-eye-slash':!showPasswordOld}"></i>
                </button>
            </div>
            <p class="text-danger mb-3">
                {{errors['old_password']}}
            </p>

            <label for="inputPassword" class="form-label">Kata Sandi Baru</label>
            <div class="input-group">
                <input v-if="!showPasswordNew" v-model="newPassword" type="password" class="form-control"
                       :class="{'is-invalid':errors['newPassword']}" id="inputPasswordNew"
                       placeholder="Insert your password here">
                <input v-else v-model.lazy="newPassword" type="text" class="form-control"
                       :class="{'is-invalid':errors['newPassword']}" id="inputPasswordNew"
                       placeholder="Insert your password here">
                <button class="btn btn-outline-secondary" id="button-addon2" @click="togglePassword('new')">
                    <i class="bi" :class="{'bi-eye':showPasswordNew, 'bi-eye-slash':!showPasswordNew}"></i>
                </button>
            </div>
            <p class="text-danger mb-3">
                {{errors['new_password']}}
            </p>

            <button class="btn text-white" :class="{'disabled':!Object.values(this.validation).every(item => item === true)}" style="background-color: #2283C1" @click="submit_password">Ubah password</button>
        </div>
    </div>
    <div class="px-4" style="font-size: 10pt">
        <span :class="{'text-info':this.validation.eight_char}"><i class="bi bi-check-circle-fill"></i> Minimal 8 Karakter</span><br>
        <span :class="{'text-info':this.validation.one_numeric}"><i class="bi bi-check-circle-fill"></i> Mengandung satu angka</span><br>
        <span :class="{'text-info':this.validation.one_capital}"><i class="bi bi-check-circle-fill"></i> Mengandung satu huruf besar</span><br>
        <span :class="{'text-info':this.validation.one_lowercase}"><i class="bi bi-check-circle-fill"></i> Mengandung satu huruf kecil</span><br>
        <span :class="{'text-info':this.validation.one_symbol}"><i class="bi bi-check-circle-fill"></i> Mengandung satu karakter spesial atau simbol</span><br>
    </div>

    <bottom-nav/>
</template>

<script>
    import Navigation from "../components/Navigation";
    import BottomNav from "../components/BottomNav";
    import axios from "axios";
    import Swal from 'sweetalert2';

    export default {
        name: "ChangePassword",
        components: {BottomNav, Navigation},
        data() {
            return {
                showPasswordOld: false,
                showPasswordNew: false,
                errors: [],
                oldPassword: '',
                newPassword: '',
                validation: {
                    eight_char: false,
                    one_numeric: false,
                    one_capital: false,
                    one_lowercase: false,
                    one_symbol: false
                }
            }
        },
        watch: {
            newPassword: function (value) {
                this.validation.eight_char = value.length >= 8
                this.validation.one_numeric = !!/\d/.test(value)
                this.validation.one_lowercase = !!/[a-z]/.test(value)
                this.validation.one_capital = !!/[A-Z]/.test(value)
                this.validation.one_symbol = !!/[!@#$%^&*()_+\-=[\]{};':"|,.<>?]+/.test(value)
            }
        },
        methods: {
            togglePassword(name) {
                if (name === 'new') {
                    this.showPasswordNew = !this.showPasswordNew
                } else {
                    this.showPasswordOld = !this.showPasswordOld

                }
            },
            async submit_password() {
                let self = this;
                if (this.oldPassword === '') {
                    this.errors['old_password'] = "Field Kata sandi lama tidak boleh kosong!"
                }
                if (this.newPassword === '') {
                    this.error['new_password'] = "Field Kata sandi baru tidak boleh kosong!"
                }
                if (this.oldPassword && this.newPassword) {
                    await axios.post('/api/change_password', {
                        user_id: self.$store.getters.getUser.id,
                        old_password: self.oldPassword,
                        new_password: self.newPassword
                    }, {withCredentials:true}).then((response) => {
                        Swal.fire({
                            icon: response.data.status ? "success": "error",
                            title: response.data.status ? "Success": "Error",
                            text: response.data.message,
                            showConfirmButton:false,
                            timer: 1500,
                        })
                        if(response.data.status)
                            self.$router.push("/profile");
                        }).catch((error) => {
                        console.log(error)
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
