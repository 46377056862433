<template>
  <Navigation title="Checkout Order" />

  <div class="vh-100 d-flex justify-content-start flex-column align-items-center">
    <div><i style="font-size: 48pt;" class="bi bi-bookmark-fill"></i></div>
    <h2>Transaksi tersimpan</h2>
    <p class="lead">Klik tombol di bawah ini untuk melakukan pembayaran</p>
    <a :href="this.checkoutData.payment_url" class="btn btn-primary" target="_blank" rel="noreferer noopener">Proses Pembayaran</a>
  </div>
</template>

<script>
import Navigation from "../components/Navigation";
import axios from "axios";
import CryptoJS from "crypto-js";

export default {
  name: "PaymentCheckout",
  components: { Navigation },
  mounted() {
    this.userData = this.$store.getters.getUser
    this.checkoutData = this.$store.getters.getCheckout
    this.packageData = this.$store.getters.getPackage

    // this.generatePayment()
  },
  created() {},
  data() {
    return {
      checkoutData: [],
      userData: [],
      packageData: [],
    };
  },
  methods: {
    generatePayment() {
      let self = this
      // adjust with your iPaymu api key & va
      
      // sandbox
      // var url = "https://sandbox.ipaymu.com/api/v2/payment"; // development mode
      // var apikey = "SANDBOXEAA6E9AF-0973-4502-B5BA-630C533A03BA";
      // var va = "0000005810947446";
      // sandbox

      // production
      var url = 'https://my.ipaymu.com/api/v2/payment'; // for production mode
      var apikey = "D00819A6-B37B-435A-AA9C-0C89DA6A1EC3";
      var va = "1179005810947446";
      // production

      var body = {
        product: [self.checkoutData.package_name],
        qty: [1],
        price: [self.checkoutData.price_sum],
        amount: "1",
        returnUrl: "http://apps.priadi.id/payment/success",
        cancelUrl: "http://apps.priadi.id/payment/unfinished",
        notifyUrl: "http://api-apps.priadi.id/api/ipaymu_confirm",
        referenceId: String(self.checkoutData.trx_id), // your reference id or transaction id
        buyerName: self.userData.fullname, // optional
        buyerPhone: self.userData.phone_number, // optional
        buyerEmail: self.userData.email, // optional
      };
      // generate signature
      var bodyEncrypt = CryptoJS.SHA256(JSON.stringify(body));
      var stringtosign = "POST:" + va + ":" + bodyEncrypt + ":" + apikey;
      var signature = CryptoJS.enc.Hex.stringify(CryptoJS.HmacSHA256(stringtosign, apikey));

      axios
        .post(url, JSON.stringify(body), {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            va: va,
            signature: signature,
            timestamp: self.getNumericDateTime(),
          },
        })
        .then((response) => {
          const url = response.data.Data.Url;
          console.log(url);
          window.location.href = url;
        });
    },
    getNumericDateTime() {
      const dateObject = new Date();

      const year = dateObject.getFullYear()
      const month = String(dateObject.getMonth() + 1).padStart(2, '0')
      const day = String(dateObject.getDate()).padStart(2, '0')
      const hours = String(dateObject.getHours()).padStart(2, '0');
      const minutes = String(dateObject.getMinutes()).padStart(2, '0');
      const seconds = String(dateObject.getSeconds()).padStart(2, '0');

      return `${year}${month}${day}${hours}${minutes}${seconds}`;
    }
  },
};
</script>

<style scoped></style>
