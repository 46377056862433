<template>
    <Navigation/>
    <div class="pb-5 bg-finger">
        <div class="container" style="height: 120px">

        </div>
        <div class="container-fluid position-absolute" style="top: 120px">
            <div class="card py-4">
                <div class="card-body">
                    <h3 class="fw-bold text-center">Biodata Testee</h3>
                    <p style="font-size: 10pt;" class="fw-normal text-center">Silahkan isi form di bawah ini sebelum tes
                        dimulai</p>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid mb-5 pb-3 padding-tab-content">
        <div class="px-4">
            <div class="form-floating mb-3">
                <input type="text" class="form-control" id="nama" placeholder="Nama Lengkap" v-model="nama">
                <label for="nama">Nama Lengkap</label>
            </div>
            <p class="text-danger">{{errors.nama}}</p>
            <div class="form-floating mb-3">
                <input type="email" class="form-control" id="email" placeholder="Email" v-model="email">
                <label for="email">Email</label>
            </div>
            <p class="text-danger">{{errors.email}}</p>
            <div class="form-floating mb-3">
                <input type="tel" class="form-control" id="phone" placeholder="Nomor Handphone" v-model="phone">
                <label for="phone">Nomor Handphone</label>
            </div>
            <p class="text-danger">{{errors.phone}}</p>
            <div class="form-floating mb-3">
                <input type="date" class="form-control" id="tgl_lahir" placeholder="Tanggal Lahir" v-model="tgl_lahir">
                <label for="tgl_lahir">Tanggal Lahir</label>
            </div>
            <p class="text-danger">{{errors.tgl_lahir}}</p>

            <div class="d-flex justify-content-between">
                <a class="fw-bold" @click="clearForm">Reset</a>
                <button class="btn btn-primary rounded-pill btn-sm w-25 fw-bold" @click="submitForm">Next</button>
            </div>
        </div>
    </div>
    <BottomNav/>
</template>

<script>
    import Navigation from "../components/Navigation";
    import BottomNav from "../components/BottomNav";
    import axios from "axios";

    export default {
        name: "AddTransaction",
        components: {BottomNav, Navigation},
        data() {
            return {
                errors: [],
                nama: this.$store.getters.getUser.fullname,
                email: this.$store.getters.getUser.email,
                phone: this.$store.getters.getUser.phone_number,
                tgl_lahir: this.$store.getters.getUser.birthdate,
                package: ''
            }
        },
        mounted() {
            this.package = this.$store.getters.getPackage
        },
        methods: {
            clearForm() {
                this.nama = ''
                this.email = ''
                this.phone = ''
                this.tgl_lahir = ''
            },
            async submitForm() {

                this.errors['nama'] = this.nama.length < 2 ? 'Field nama harus berisi minimal 2 karakter.' : ''
                this.errors['email'] = this.email.length < 3 ? 'Field email harus berisi minimal 3 karakter.' : ''
                this.errors['tgl_lahir'] = !this.tgl_lahir ? 'Field tanggal lahir tidak boleh kosong.' : ''
                this.errors['phone'] = !this.phone ? 'Field nomor handphone tidak boleh kosong.' : ''

                if (!this.errors['nama'] && !this.errors['email'] && !this.errors['tgl_lahir'] &&
                    !this.errors['nomor_handphone']) {
                    let self = this

                    const formData = {
                        user_id: this.$store.getters.getUser.id,
                        fullname: this.nama,
                        email: this.email,
                        birthdate: this.tgl_lahir,
                        phone: this.phone,
                        language_id: 'id',
                        package_id: this.package.id,
                        package_name: this.package.name,
                        is_hr: false,
                        due_date: null,
                        total_price: this.package.total_price,
                        voucher_code: this.package.voucher_code ?? "",
                        counselor_code: this.package.counselor_name ?? "",
                        consultation_type: this.package.consultation_type ?? "",
                    }

                    await axios.post('/api/create_transaction', formData)
                        .then((response) => {
                            console.log(response)
                            let transaction_id = response.data.transaction_id
                            self.$store.dispatch('setTransaction', transaction_id)
                            self.$router.push({name: 'Capture'})
                        }).catch((error) => {
                            console.log(error)
                        })
                }
            }
        }
    }
</script>

<style scoped>
    .bg-finger {
        background: #08A0F1 url("/img/fpimageup.png");
        background-size: cover;
    }

    @media only screen and (min-width: 320px) {
        .padding-tab-content {
            padding-top: 5.5em;
        }

        .padding-card-promo {
            padding-top: 1.5em;
        }

        .package-name {
            font-size: 10pt;
        }

        .package-target {
            font-size: 8pt;
        }

        .package-consultation {
            font-size: 7pt;
        }

        .package-price {
            font-size: 7pt;
        }

        .package-btn {
            font-size: 9pt;
        }

        .consult-check-label {
            font-size: 8pt;
        }
    }

    @media only screen and (min-width: 375px) {
        .padding-tab-content {
            padding-top: 4.5em;
        }

        .padding-card-promo {
            padding-top: 1.5em;
        }

        .package-name {
            font-size: 12pt;
        }

        .package-target {
            font-size: 10pt;
        }

        .package-consultation {
            font-size: 9pt;
        }

        .package-price {
            font-size: 10pt;
        }

        .package-btn {
            font-size: 10pt;
        }

        .consult-check-label {
            font-size: 8pt;
        }
    }

    @media only screen and (min-width: 425px) {
        .padding-tab-content {
            padding-top: 4.5em;
        }

        .padding-card-promo {
            padding-top: 2em;
        }

        .package-name {
            font-size: 13pt;
        }

        .package-target {
            font-size: 10pt;
        }

        .package-consultation {
            font-size: 10pt;
        }

        .package-price {
            font-size: 10pt;
        }

        .package-btn {
            font-size: 10pt;
        }

        .consult-check-label {
            font-size: 9pt;
        }
    }

    @media only screen and (min-width: 768px) {
        .padding-tab-content {
            padding-top: 5em;
        }

        .padding-card-promo {
            padding-top: 3em;
        }

        .package-name {
            font-size: 18pt;
        }

        .package-target {
            font-size: 12pt;
        }

        .package-consultation {
            font-size: 12pt;
        }

        .package-price {
            font-size: 12pt;
        }

        .package-btn {
            font-size: 14pt;
        }

        .consult-check-label {
            font-size: 12pt;
        }
    }
</style>
