<template>
    <div class="d-flex justify-content-between align-items-center sticky-top py-2 bg-secondary">
        <i class="bi-arrow-left btn-click-effect text-white ripple rounded-circle px-2 py-0" style="font-size: 1.8em" @click="goBack(goto, numback)"></i>
        <span class="text-center text-white" style="font-size: 11pt">{{title}}</span>
        <i class="bi-arrow-left btn-click-effect text-white ripple rounded-circle px-2 opacity-0 disabled-component" style="font-size: 1.8em" @click="goBack(goto, numback)"></i>
    </div>
</template>

<script>
    export default {
        name: "Navigation",
        props: {
            title: String,
            goto: {
                default: '',
                type: String
            },
            numback: {
                default: '',
                type: String
            }
        },
        methods: {
            goBack(location, back) {
                location ? this.$router.push(location) : (back ? this.$router.go(back) : this.$router.back());
            }
        }
    }
</script>

<style scoped>

</style>
