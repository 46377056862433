<template>
    <Navigation :title="capture_title"/>
    <div class="pb-5" v-show="!isTutorialWatched">
        <div class="text-center mt-3">
            <p class="lead m-0">Sebelum Melanjutkan,</p>
            <p>Simak terlebih dahulu tutorial singkat dibawah ini:</p>
        </div>

        <iframe id="tutorialPlayer" class="w-100 shadow" style="height: 50vh!important;" src="https://www.youtube.com/embed/wVqee9FSBEA?rel=0" title="Tutorial Singkat Pengambilan Sidik Jari" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        <p class="text-center my-2" v-if="countdown_tutorial>0">Sedang menyiapkan kamera... <br>Mohon tunggu {{countdown_tutorial}} detik</p>
        <div class="d-grid col-6 mx-auto mt-2 shadow">
            <button type="button" class="btn btn-primary" :class="{'disabled':!isTutorialFinish}" id="flashBtn">
                Ambil Sidik Jari
            </button>
        </div>
    </div>

    <Transition name="fade">
        <div class="pb-5" v-show="isTutorialWatched">
            <div class="well" v-show="!isPhotoTaken">
                <video ref="camera" class="w-75 mx-auto d-block" autoplay loop muted playsinline></video>
                <div id="rb" class="scanner-laser laser-rightBottom"></div>
                <div class="scanner-laser laser-rightTop"></div>
                <div class="scanner-laser laser-leftBottom"></div>
                <div class="scanner-laser laser-leftTop"></div>
                <div class="text-center my-1 position-absolute w-100" style="bottom: 3%" v-show="!isPhotoTaken && selected_finger">
                    <button type="button" class="btn btn-light border border-5 border-info btn-capture text-info"
                            @click="takePhoto">
                        <i class="bi bi-camera-fill"></i>
                    </button>
                </div>
            </div>
            <img v-show="isPhotoTaken" v-bind:src="captured_image" class="w-100 mx-auto" :class="{'d-block':isPhotoTaken}" alt="">
            <canvas id="photoTaken" class="mx-auto mb-0 d-none" ref="canvas" :width="360" :height="480"></canvas>
            <div class="container py-3" v-show="!isPhotoTaken" style="background-color:#EEEEEE">
                <div v-for="finger in fingers" :key="finger">
                    <p class="mx-0 mb-1">{{finger.name}}</p>
                    <div class="row row-cols-5 g-2 mb-2">
                        <div class="col"
                             :class="{'disabled-component':fingers_done[fname], 'click-effect':!fingers_done[fname]}"
                             v-for="(fname) in finger.fingers_name" :key="fname" @click="changeFinger(fname)">
                            <div class="text-center border border-3" style="border-radius: 20%"
                                 :class="{'border-done':fingers_done[fname], 'border-info':fname === selected_finger}">
                                <img v-bind:src="`/img/finger/${fname}.jpg`" style="border-radius: 20%" class="w-100" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container pb-3" v-show="isPhotoTaken">
                <p class="text-center text-muted fw-bold" style="font-size: 14pt">Apakah foto ini sudah jelas ?</p>
                <p class="text-center text-muted" style="font-size: 8pt">Jika belum, klik tombol "Foto Ulang". Jika sudah,
                    klik tombol "Melanjutkan"</p>
                <div class="d-flex justify-content-around">
                    <button class="btn btn-outline-info" @click="takePhoto">Foto Ulang</button>
                    <button class="btn btn-info text-white" @click="sendPicture">Melanjutkan</button>
                </div>
            </div>

            <div class="d-grid g-2 col-12 mx-auto" v-show="isPhotoTaken" v-if="Object.values(this.fingers_done).every(item => item === true)">
                <button type="button" class="btn btn-success text-white rounded-0" @click="finish" data-bs-dismiss="modal">
                    Selesai
                </button>
            </div>
        </div>
    </Transition>

    <BottomNav/>

    <div class="position-absolute top-50 start-50 translate-middle p-3" style="z-index: 1">
        <div id="liveToast" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="10000">
            <div class="toast-header">
                <strong class="me-auto">Peringatan</strong>
                <small>Baru saja</small>
                <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
            <div class="toast-body">
<!--                Flash (senter) kamera tidak didukung, mohon update ke versi chrome terbaru atau jalankan di handphone lain.-->
<!--                <br><br>-->
                Daftar plugin yang disupport device & browser anda: <br>
                <div class="capabilities-list"></div>
            </div>
        </div>
    </div>

    <!-- Button trigger modal -->
    <button type="button" id="btn_modal" style="display: none" class="btn btn-primary" data-bs-toggle="modal"
            data-bs-target="#staticBackdrop">
        PRiADI Tips
    </button>

    <!-- Modal -->
    <div class="modal fade bg-info" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
         aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content"
                 style="background-color: transparent!important; border: 0 solid transparent!important;">
                <h4 class="text-center text-white mt-3 mb-5">Foto Cap Sidik Jari</h4>
                <div class="modal-body rounded rounded-bottom mb-5" style="background-color: white">
                    <h4 class="text-center fw-bold mb-4">Petunjuk Foto Cap Sidik Jari</h4>
                    <div v-for="(tip, index) in tips" :key="tip">
                        <Transition name="list">
                            <div class="d-flex mb-2" v-if="tip.show">
                                <!-- <div class="align-self-center border bullet_number">
                                    {{index+1}}
                                </div> -->
                                <!-- <div class="align-self-center me-3">
                                    <input type="checkbox" name="" id="">
                                </div>
                                <div class="align-self-center" v-html="tip.data">
                                </div> -->
                                <div class="form-check">
                                    <input class="form-check-input" type="checkbox" value="" :id="`tips${index}`" @click="toggleTutorial(index)" :disabled="tip.checked">
                                    <label class="form-check-label" :for="`tips${index}`" v-html="tip.data">
                                    </label>
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
                <div class="modal-footer border-0">
                    <Transition name="fade">
                        <div class="d-grid col-6 mx-auto" v-if="isTipsFinish">
                            <button type="button" class="btn" id="" @click="this.isTimerEnabled=true" style="background-color: yellow" data-bs-dismiss="modal">
                                Lanjutkan
                            </button>
                        </div>
                    </Transition>
                </div>
            </div>
        </div>
    </div>
    <button class="d-none" id="flashBtnOff">Turn of flash</button>
</template>

<script>
    import Navigation from "../components/Navigation";
    import BottomNav from "../components/BottomNav";
    import Swal from 'sweetalert2'
    import axios from 'axios'
    // import {Toast} from 'bootstrap'

    require('form-data')

    export default {
        name: "Capture",
        components: {BottomNav, Navigation},
        mounted() {
            document.getElementById("btn_modal").click()
            this.isCameraOpen = true;
            this.isTutorialWatched = false;
            this.createCameraElement()
            this.transaction_id = this.$store.getters.getTransaction
            this.checkFinger()
            this.getTransactionData()
            // this.tips.forEach((tip, idx) => {
            //     this.delayedList(tip, idx+1)
            // })
        },
        data() {
            return {
                transaction_id: '',
                transaction_data: {},
                fingers: [
                    {
                        name: "Tangan Kiri",
                        fingers_name: ["left_thumb", "left_forefinger", "left_middlefinger", "left_thirdfinger", "left_littlefinger"]
                    },
                    {
                        name: "Tangan Kanan",
                        fingers_name: ["right_thumb", "right_forefinger", "right_middlefinger", "right_thirdfinger", "right_littlefinger"]
                    },
                ],
                tips: [
                    {data: "Siapkan bak stempel dan kertas putih", show: true, checked:false},
                    {data: "Pastikan tinta pada bak stempel tidak terlalu basah sehingga pada saat pengambilan tidak luber", show: false, checked:false},
                    {data: "Siapkan tisu atau lap seandainya diperlukan", show: false, checked:false},
                    {data: "Pastikan kondisi jari-jari tangan dalam keadaan kering", show: false, checked:false},
                    {data: "Mulai dengan menempelkan jari jempol kiri ke bak stempel kemudian tempelkan jari tersebut pada kertas putih", show: false, checked:false},
                    {data: "Foto hasil cap jari tadi dengan menggunakan aplikasi yang tersedia", show: false, checked:false},
                    {data: "Lakukan pengambilan cap jari lainnya seperti prosedur diatas sesuai dengan ketentuan urutan masing-masing jari di aplikasi", show: false, checked:false},
                ],
                isPhotoTaken: false,
                isCameraOpen: false,
                isShotPhoto: false,
                isLoading: false,
                selected_finger: '',
                fingers_done: {
                    left_thumb: false,
                    left_forefinger: false,
                    left_middlefinger: false,
                    left_thirdfinger: false,
                    left_littlefinger: false,
                    right_thumb: false,
                    right_forefinger: false,
                    right_middlefinger: false,
                    right_thirdfinger: false,
                    right_littlefinger: false
                },
                capture_title: "Foto Sepuluh Sidik Jari",
                camera_size: {
                    width: '',
                    height: '',
                },
                captured_image: '',
                isTutorialWatched: false,
                isTutorialFinish: false,
                countdown_tutorial: 15,
                isTimerEnabled: false,
                countdown_tips: 7,
                isTipsFinish: false
            }
        },
        methods: {
            changeFinger(finger_name) {
                this.selected_finger = finger_name;
                this.capture_title = finger_name.replace(/_/g," ").toUpperCase()
            },
            createCameraElement() {
               let self = this;

                this.isLoading = true;
                const constraints = {
                    audio: false,
                    video: {
                        facingMode: "environment"
                    },
                }

                navigator.mediaDevices.getUserMedia(constraints).then((stream) => {
                    this.isLoading = false;
                    this.$refs.camera.srcObject = stream;

                    const [track] = stream.getVideoTracks();
                    const capabilities = track.getCapabilities();
                    if (track.getSettings) {
                        let {width, height} = track.getSettings();
                        this.camera_size.width = width
                        this.camera_size.height = height
                    }
                    // console.log(capabilities.zoom.min);
                    // console.log(capabilities.zoom.max);
                    // console.log(capabilities.zoom.step);
                    // console.log(capabilities.torch);

                    const btnFlash = document.getElementById('flashBtn')
                    btnFlash.addEventListener('click', function () {
                        if(capabilities?.zoom !== undefined){
                            let zoom_value = capabilities.zoom.min * 2;
                            track.applyConstraints({
                                advanced: [{torch: true, zoom: zoom_value}],
                            });
                        } else {
                            track.applyConstraints({
                                advanced: [{torch: true}],
                            });
                        }
                        self.isTutorialWatched = true;
                    })

                    const btnFlashOff = document.getElementById('flashBtnOff')
                    btnFlashOff.addEventListener('click', function () {
                        track.applyConstraints({
                            advanced: [{torch: false}],
                        });
                    })

                    // if(track.getCapabilities()['torch'] === undefined){
                    //     let toast = new Toast(document.getElementById("liveToast"))
                    //     for(let cap in track.getCapabilities()){
                    //         document.getElementsByClassName('capabilities-list')[0].append(cap+", ")
                    //     }
                    //     toast.show()
                    // }
                    // track.applyConstraints({
                    //     advanced: [{zoom: 2}],
                    // });
                })
            },
            takePhoto() {
                if (!this.isPhotoTaken) {
                    this.isShotPhoto = true;

                    const FLASH_TIMEOUT = 50;

                    setTimeout(() => {
                        this.isShotPhoto = false;
                    }, FLASH_TIMEOUT);
                }

                this.isPhotoTaken = !this.isPhotoTaken;
                const imgSrc = this.$refs.camera;
                const canvasPreview = document.querySelector("#photoTaken");
                const context = canvasPreview.getContext("2d");
                context.imageSmoothingEnabled = false;
                context.mozImageSmoothingEnabled = false;
                context.webkitImageSmoothingEnabled = false;
                context.msImageSmoothingEnabled = false;

                context.canvas.width = this.camera_size.width
                context.canvas.height = this.camera_size.height

                context.drawImage(imgSrc, 0, 0, this.camera_size.width, this.camera_size.height, 0, 0, this.camera_size.width, this.camera_size.height);
                this.captured_image = document.getElementById("photoTaken").toDataURL('image/jpg', 1.0)
            },
            async sendPicture() {
                let self = this
                let finger_image = document.getElementById("photoTaken").toDataURL('image/jpg', 1.0)
                let trans_id = this.transaction_id
                let finger_name = this.selected_finger

                let form = new FormData()
                form.append('id', trans_id)
                form.append('fingername', finger_name)
                form.append('image', finger_image)

                await axios.post('/api/update_finger_stamp', form)
                    .then(function (response) {
                        let swal_icon = response.data.status ? 'success':'error'
                        let swal_text = response.data.status ? 'Tersimpan':'Gagal'
                        Swal.fire({
                            icon: swal_icon,
                            title: swal_text,
                            showConfirmButton: false,
                            timer: 1500
                        })
                        if(response.data.status){
                            self.fingers_done[self.selected_finger] = true;
                            self.selected_finger = ''
                        }

                        self.takePhoto()
                    }).catch(function (error) {
                        console.log(error)
                    })
            },
            finish() {
                let self = this
                let send_url;
                let send_data;
                if(this.$store.getters.getRole==='hr'){
                    send_url = "/api/send_person_priadi"
                    send_data = {transaction_id: self.transaction_id}
                } else {
                    send_url = "/api/set_done"
                    send_data = {id: self.transaction_id}
                }
                axios.post(send_url, send_data).then(function () {
                    // Swal.showLoading()
                    if(self.$store.getters.getRole==='hr') {
                        Swal.fire({
                            icon: 'success',
                            title: 'Sukses!',
                            text: '10 Gambar Sidik jari anda telah dikirim',
                            showDenyButton: false,
                            showCancelButton: false,
                            confirmButtonText: 'Kembali ke transaksi',
                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                self.$router.push("/transactions")
                            }
                        })
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Sukses!',
                            text: '10 Gambar Sidik jari anda telah dikirim',
                            showDenyButton: true,
                            showCancelButton: true,
                            confirmButtonText: 'Lanjutkan ke pembayaran',
                            denyButtonText: 'Kembali ke transaksi',
                            cancelButtonText: 'Ambil tes lain',
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,

                        }).then((result) => {
                            /* Read more about isConfirmed, isDenied below */
                            if (result.isConfirmed) {
                                let formData = {}

                                axios.post('/api/create_invoice', {
                                    user_id: self.$store.getters.getUser.id,
                                    transaction_id: self.transaction_id
                                }).then((response)=>{
                                    formData.payment_id = response.data.id
                                    formData.trx_id = self.transaction_id
                                    formData.price_sum = self.transaction_data.total_price
                                    self.$store.dispatch('setCheckout', formData)
                                    self.$router.push('/payment')
                                })
                            } else if(result.isDenied) {
                                self.$router.push("/transactions")
                            } else if(result.isDismissed) {
                                self.$router.push("/package")
                            }
                        })
                    }
                }).catch(function (error) {
                    console.log(error)
                })
                // Swal.fire({
                //     title: 'Mengirim sidik jari',
                //     html: 'Mohon tunggu.',
                //     timer: 2000,
                //     timerProgressBar: true,
                //     didOpen: () => {
                //
                //     },
                // }).then((result) => {
                //     /* Read more about handling dismissals below */
                //     if (result.dismiss === Swal.DismissReason.timer) {
                //
                //     }
                // })
            },
            async checkFinger() {
                let self = this
                await axios.post('/api/check_finger', {id: this.transaction_id})
                    .then(function (response){
                        let response_data = response.data;
                        Object.keys(response_data).forEach(function (key) {
                            if(response_data[key] !== null){
                                self.fingers_done[key] = true
                            }
                        })
                        // console.log(response_data['right_forefinger'])
                    }).catch(function (error) {
                        console.log(error)
                    })
            },
            async getTransactionData() {
                let self = this
                await axios.post('/api/check_transaction', {id: this.transaction_id})
                    .then(function (response){
                        self.transaction_data = response.data;
                    }).catch((error) => console.log(error))
            },
            async startCountdown(){
                this.isTimerEnabled = true
            },
            delayedList(tips, idx){
                let delay = 1500 * idx;
                setTimeout(()=> {
                    tips.show = true
                }, delay)
            },
            toggleTutorial(id) {
                this.tips[id].checked = true;

                if(this.tips.length - 1 == id){
                    this.isTipsFinish = true
                } else {
                    this.tips[id+1].show = true
                }
            }
        },
        unmounted() {
            document.getElementById('flashBtnOff').click()
        },
        watch: {
            isTimerEnabled(value) {
                if (value) {
                    setTimeout(() => {
                        this.countdown_tutorial--;
                    }, 1000);
                }
            },

            countdown_tutorial: {
                handler(value) {

                    if (value > 0 && this.isTimerEnabled) {
                        setTimeout(() => {
                            this.countdown_tutorial--;
                        }, 1000);
                    }

                    if(value === 0){
                        this.isTutorialFinish = true;
                    }
                },
                immediate: true // This ensures the watcher is triggered upon creation
            },

            // countdown_tips: {
            //     handler(value) {
            //         if(value>0){
            //             setTimeout(()=>{
            //                 this.countdown_tips--;
            //             }, 1500)
            //         }
            //         if(value === 0){
            //             this.isTipsFinish = true;
            //         }
            //     },
            //     immediate: true // This ensures the watcher is triggered upon creation
            // }

        }
    }
</script>

<style scoped>
    .btn-capture {
        width: 70px;
        height: 70px;
        border-radius: 35px;
        padding: 0;
        font-size: 2em !important;
    }

    .bullet_number {
        margin-right: 20px;
        padding: 1px 9px;
        border-radius: 100%;
        color: lightslategrey;
        border: 2px solid black;
    }

    .border-done {
        border-color: #0EF522 !important;
    }

    .scanner-laser {
        position: absolute;

        height: 80px;
        width: 60px;
        opacity: 1;
    }

    .laser-leftTop {
        top: 20%;
        left: 35%;
        border-top: solid white 3px;
        border-left: solid white 3px;
    }

    .laser-leftBottom {
        bottom: 40%;
        left: 35%;
        border-bottom: solid white 3px;
        border-left: solid white 3px;
    }

    .laser-rightTop {
        top: 20%;
        right: 35%;
        border-top: solid white 3px;
        border-right: solid white 3px;
    }

    .laser-rightBottom {
        bottom: 40%;
        right: 35%;
        border-bottom: solid white 3px;
        border-right: solid white 3px;
    }

    .well {
        position: relative;
        display: inline-block;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.5s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0;
    }

    .list-enter-active,
    .list-leave-active {
        transition: all 0.5s ease;
    }
    .list-enter-from,
    .list-leave-to {
        opacity: 0;
        transform: translateX(30px);
    }

    .form-check-input:disabled~.form-check-label, .form-check-input[disabled]~.form-check-label {
        color: black !important;
        opacity: 1;
    }
</style>
