<template>
    <Navigation title="Syarat dan Ketentuan"/>
    <div class="container-fluid  py-4" v-html="nda.content"></div>
    <nav class="navbar navbar-light border bg-light mt-5" style="padding: 0.75em 0 0.75em 0" v-if="isRead==='no'">
        <div class="container-fluid">
            <div class="d-flex mb-3">
                <div class="align-self-center me-3">
                    <i class="fas fa-shield-check"></i>
                </div>
                <div class="align-self-center lh-sm">
                    <p style="font-size: 9pt" class="m-0">By pressing Agree & Continue, you have agreed to the "Non-Disclosure Agreement" written above.</p>
                </div>
            </div>
            <div class="d-grid gap-2 mx-auto col-12" style="height: 45px">
                <button class="btn btn-success" @click="transactionForm">Agree & Continue</button>
            </div>
        </div>
    </nav>
</template>

<script>
    import axios from 'axios'
    import Navigation from "../components/Navigation";

    export default {
        name: "NDA",
        data() {
            return {
                nda: '',
                isRead : ''
            }
        },
        components: {Navigation},
        created() {
            let self = this;
            axios.get('https://api.priadi.id/api/terms?language=2',{headers:{'x-api-key':12345}})
                .then((response)=> {
                    self.nda = response.data.reference;
                }).catch((error)=> {
                console.log(error)
            })
            this.isRead = this.$route.params.is_read
        },
        methods: {
            transactionForm(){
                this.$router.push('/add_transaction');
            }
        }
    }
</script>

<style scoped>

</style>
