<template>
    <div class="main-section py-5 px-3">
        <div class="container-fluid text-white">
            <img src="/img/logopriadi.png" class="mx-auto d-block mb-4" style="width: 40%" alt="">
            <p class="fw-bold fs-4 text-center mb-0" style="color:yellow">Daftar Akun</p>
            <p class="text-center" style="font-size: 9pt">Untuk membuat akun, silahkan isi form di bawah</p>

            <div class="form px-2">
                <div class="mb-3">
                    <label class="form-label">Nama Lengkap</label>
                    <input type="text" v-model="nama_lengkap" class="form-control">
                    <small class="text-danger">{{errors['nama_lengkap']}}</small>
                </div>
                <div class="mb-3">
                    <label class="form-label">Email</label>
                    <input type="email" v-model="alamat_email" class="form-control">
                    <small class="text-danger">{{errors['alamat_email']}}</small>
                </div>
                <div class="mb-3">
                    <label class="form-label">Buat Kata Sandi</label>
                    <input type="password" v-model="kata_sandi" class="form-control">
                    <small class="text-danger">{{errors['kata_sandi']}}</small>
                </div>
                <div class="mb-3">
                    <label class="form-label">Ulangi Kata Sandi</label>
                    <input type="password" v-model="kata_sandi_confirm" class="form-control">
                    <small class="text-danger">{{errors['kata_sandi_confirm']}}</small>
                </div>
                <div class="mb-3">
                    <label class="form-label">Nomor Handphone</label>
                    <input type="tel" v-model="nomor_handphone" class="form-control">
                    <small class="text-danger">{{errors['nomor_handphone']}}</small>
                </div>
                <div class="row mb-3">
                    <label class="form-label">Tanggal Lahir</label>
                    <div style="padding-right: .8em; padding-left: .8em">
                        <input type="date" v-model="tgl_lahir" class="form-control">
                    </div>
                    <small class="text-danger">{{errors['tgl_lahir']}}</small>
                </div>

                <div class="form-check" style="font-size: 11pt" @click="toggleAgree">
                    <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                    <label class="form-check-label" for="flexCheckDefault">
                        Saya setuju dengan
                        <router-link to="/nda" class="text-decoration-underline fw-bold" style="color: greenyellow">
                            Syarat dan Ketentuan
                        </router-link>
                        PRiADI
                    </label>
                </div>

                <button class="btn d-block mx-auto my-3" :class="isAgree ? '' : 'disabled'"
                        style="background-color: #B5FF06" @click="submitForm">DAFTAR
                </button>

                <p class="text-center">Sudah punya akun ?
                    <router-link to="/auth" class="fw-bold text-decoration-none" style="color: yellow">Masuk
                    </router-link>
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import Swal from 'sweetalert2'

    export default {
        name: "Register",
        data() {
            return {
                errors: [],
                nama_lengkap: '',
                alamat_email: '',
                kata_sandi: '',
                kata_sandi_confirm: '',
                tgl_lahir: '',
                nomor_handphone: '',
                isAgree: false
            }
        },
        methods: {
            toggleAgree() {
                this.isAgree = !this.isAgree
            },
            async submitForm() {
                this.errors['nama_lengkap'] = this.nama_lengkap.length < 2 ? 'Field nama harus berisi minimal 2 karakter.' : ''
                this.errors['alamat_email'] = this.alamat_email.length < 3 ? 'Field email harus berisi minimal 3 karakter.' : ''
                this.errors['kata_sandi'] = this.kata_sandi.length < 8 ? 'Field kata sandi harus berisi minimal 8 karakter' : ''
                this.errors['kata_sandi_confirm'] = this.kata_sandi_confirm !== this.kata_sandi ? 'Kata sandi tidak cocok.' : ''
                this.errors['tgl_lahir'] = !this.tgl_lahir ? 'Field tanggal lahir tidak boleh kosong.' : ''
                this.errors['nomor_handphone'] = !this.nomor_handphone ? 'Field nomor handphone tidak boleh kosong.' : ''

                if (!this.errors['nama_lengkap'] && !this.errors['alamat_email'] && !this.errors['kata_sandi']
                    && !this.errors['kata_sandi_confirm'] && !this.errors['tgl_lahir'] && !this.errors['nomor_handphone']) {
                    let self = this

                    const formData = {
                        fullname: this.nama_lengkap,
                        email: this.alamat_email,
                        password: this.kata_sandi,
                        birthdate: this.tgl_lahir,
                        phone_number: this.nomor_handphone
                    }

                    await axios.post('/api/register', formData).then(function (response) {
                        if (response.data.message) {
                            let timerInterval;
                            Swal.fire({
                                title: 'Berhasil',
                                icon: 'success',
                                text: 'Mengarahkan ke halaman login',
                                timer: 2000,
                                allowOutsideClick: false,
                                didOpen: () => {
                                    Swal.showLoading()
                                },
                                willClose: () => {
                                    clearInterval(timerInterval)
                                }
                            }).then(function (result) {
                                /* Read more about handling dismissals below */
                                if (result.dismiss === Swal.DismissReason.timer) {
                                    self.$router.push('auth')
                                }
                            })
                        }
                    })
                }
            }
        }
    }
</script>

<style scoped>
    .main-section {
        background-image: linear-gradient(to top, rgba(0, 119, 138, .6), rgba(0, 187, 216, .6)), url("/img/login.png");
        background-size: contain;
        background-position: center;
        background-attachment: fixed;
        background-repeat: no-repeat;
    }
</style>
