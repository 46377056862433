<template>
    <Navigation title="Konfirmasi Pembayaran"/>
    <div class="pb-5 bg-finger">
        <div class="container" style="height: 120px">

        </div>
        <div class="container-fluid position-absolute" style="top: 120px">
            <div class="card py-4">
                <div class="card-body">
                    <h4 class=" text-center">Jumlah yang harus dibayarkan :</h4>
                    <p class="fw-bold fs-4 text-center">
                        {{'Rp.'+Intl.NumberFormat('en-US').format(checkoutData.price_sum)}}</p>
                    <div class="d-block text-center mb-3 fw-bold">BNI 353664685 A/N Dedi Priadi</div>
                    <p class="text-center fw-bold">*mohon upload bukti transfer agar data dapat segera diproses.</p>
                    <button class="btn btn-outline-primary d-block mx-auto" data-bs-toggle="modal"
                            data-bs-target="#exampleModal"><i class="bi bi-upload"></i> Upload Bukti
                        Transfer
                    </button>
                </div>
            </div>
        </div>
    </div>
    <div class="container-fluid mb-5 pb-3" style="padding-top: 15em">
        <div class="fs-3 text-center mb-3 fw-bold">
            <i class="bi bi-info-circle"></i> Informasi penting
        </div>
        <ol>
            <li class="mb-2">Lakukan pembayaran sesuai dengan nominal yang tertera ke rekening yang tercantum di atas
            </li>
            <li class="mb-2">Pembayaran dilakukan selambat-lambatnya 2(dua) hari setelah melakukan tes</li>
            <li class="mb-2">Hasil tes akan dikirimkan melalui email yang terdaftar pada transaksi selambatnya 3(tiga)
                hari
                kerja setelah pembayaran
            </li>
            <li class="mb-2">Transaksi dianggap batal apabila tanggal pembayaran melebihi ketentuan</li>
        </ol>
    </div>
    <BottomNav/>

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Upload Bukti Transfer</h5>
                </div>
                <div class="modal-body">
                    <input type="file" ref="file" class="form-control" @change="select_img">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                    <button type="button" class="btn btn-primary" @click="upload" data-bs-dismiss="modal">Upload</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Navigation from "../components/Navigation";
    import BottomNav from "../components/BottomNav";
    import axios from 'axios'
    import Swal from "sweetalert2";

    export default {
        name: "Payment",
        components: {BottomNav, Navigation},
        data() {
            return {
                checkoutData: [],
                transfer_img: undefined
            }
        },
        mounted() {
            this.checkoutData = this.$store.getters.getCheckout
            document.body.style.backgroundColor = '#EEEFFF'
        },
        unmounted() {
            this.$store.dispatch('setCheckout', {})
            document.body.style.backgroundColor = '#FFFFFF'
        },
        methods: {
            select_img() {
                this.transfer_img = this.$refs.file.files
            },
            async upload() {
                let self = this
                let formData = new FormData();
                formData.append('id', this.checkoutData.payment_id)
                formData.append('transfer_img', this.transfer_img.item(0))

                await axios.post('/api/upload_invoice', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(()=>{
                        self.$router.push('/transactions')
                    }).catch((error)=>{
                        Swal.fire('Gagal', 'Terjadi kesalahan saat upload gambar', 'error')
                        console.log(error)
                    })
            }
        }
    }
</script>

<style scoped>
    .bg-finger {
        background: #08A0F1 url("/img/fpimageup.png");
        background-size: cover;
    }
</style>
