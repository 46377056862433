<template>
    <div class="vh-100 main-frame" :style="bgImg">
        <div class="shade-layer text-center pt-5">
            <p class="mt-2 mb-0" style="font-size: 50pt"><i class="bi bi-x-circle-fill"></i></p>
            <p class="fw-bold fs-2">Pembayaran Gagal.</p>
            <p class="mx-4">Jika anda mengalami masalah yang sama secara berulang, mohon hubungi
                <router-link to="/contact" target="_blank">customer service</router-link> PRiADI untuk ditindaklanjuti
            </p>
            <button class="btn btn-dark" @click="returnToTransaction">Kembali ke Transaksi</button>
        </div>
    </div>
</template>

<script>
    export default {
        name: "PaymentFailed",
        data() {
            return {
                bgImg: {
                    backgroundImage: `url(${require('../../public/img/undraw_cancel_re_pkdm.svg')})`,
                    position: 'relative',
                    backgroundPosition: 'bottom',
                    backgroundSize: '75% 75%',
                    backgroundRepeat: 'no-repeat'
                }
            }
        },
        methods: {
            returnToTransaction(){
                this.$router.replace('/transactions')
            }
        }
    }
</script>

<style scoped>
    .shade-layer {
        background-color: rgba(210, 210, 207, 0.9);
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>
